<template>
  <div class="consumer-main">
    <!-- 第一屏 -->
    <div class="consumer-top">
      <div class="img-box">
        <img src="@/assets/consumer/bg3.png" />
      </div>
      <div :class="['title-box', { namelang: $i18n.locale === 'en' }]">
        <div class="title">{{ $t("consumer.page1.title1") }}</div>
        <div class="tips">{{ $t("consumer.page1.title2") }}</div>
        <div class="more">{{ $t("consumer.page1.title3") }}</div>
      </div>
    </div>
    <!-- 第二屏 -->
    <div class="consumer-second">
      <div class="card-item" v-for="(item, index) in secondList" :key="index">
        <img :src="item.url" />
        <div class="title">{{ $t(`consumer.page2.name${index + 1}`) }}</div>
        <div class="tips">{{ $t(`consumer.page2.tips${index + 1}`) }}</div>
        <div class="tips">
          {{ $t(`consumer.page2.tips${index + 1}${index + 1}`) }}
        </div>
      </div>
    </div>
    <!-- 第三屏 -->
    <div class="massive">
      <div class="massive-box">
        <div class="title-box">
          <div class="title">{{ $t("consumer.page3.right.title1") }}</div>
          <div class="tips">{{ $t("consumer.page3.right.tips1") }}</div>
          <div class="tips">{{ $t("consumer.page3.right.tips2") }}</div>
        </div>
        <div class="left">
          <img class="bg2" src="@/assets/consumer/bg2.png" />
          <img class="circle" src="@/assets/consumer/circle.png" />
          <img class="people" id="people" src="@/assets/consumer/people.png" />

          <div class="title-boxs">
            <div class="left-boxs">
              <div class="title">{{ $t("consumer.page3.left.title1") }}</div>
              <div class="tips">{{ $t("consumer.page3.left.tips1") }}</div>
            </div>
            <div class="middle-boxs">
              <div class="title">{{ $t("consumer.page3.left.title2") }}</div>
              <div class="tips">{{ $t("consumer.page3.left.tips2") }}</div>
            </div>
            <div class="right-boxs">
              <div class="title">{{ $t("consumer.page3.left.title3") }}</div>
              <div class="tips">{{ $t("consumer.page3.left.tips3") }}</div>
            </div>
          </div>

          <button class="money-btn">
            <img src="@/assets/consumer/money.png" />{{
              $t("consumer.page3.left.name")
            }}
          </button>
        </div>
        <div :class="['right', { rightz: $i18n.locale === 'zn' }]">
          <div class="title">{{ $t("consumer.page3.right.title1") }}</div>
          <div class="tips">{{ $t("consumer.page3.right.tips1") }}</div>
          <div class="tips">{{ $t("consumer.page3.right.tips2") }}</div>

          <div class="line-box" v-for="(item, index) in lableList" :key="index">
            <div class="lable-box">
              <div class="lable-name">
                {{ $t(`consumer.page3.right.labels${index + 1}.name`) }}
              </div>

              <div class="lable" v-for="cItem in item.number" :key="cItem">
                {{
                  $t(`consumer.page3.right.labels${index + 1}.label${cItem}`)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 质量保证 -->
    <div class="quality-assurance">
      <div class="left">
        <div class="title">{{ $t("consumer.page4.title1") }}</div>
        <div class="tips-box">
          <div class="tips">{{ $t("consumer.page4.tips1") }}</div>
          <div class="tips">{{ $t("consumer.page4.tips2") }}</div>
        </div>
        <div class="tips-box">
          <div class="tips">{{ $t("consumer.page4.tips3") }}</div>
          <div class="tips">{{ $t("consumer.page4.tips4") }}</div>
        </div>
      </div>
      <div class="right">
        <div class="img-box">
          <img class="elliptic" src="@/assets/consumer/elliptic.png" />
          <img
            class="center-circle"
            src="@/assets/consumer/center-circle.png"
          />

          <div
            :class="`card-box ${item}`"
            v-for="(item, index) in svgList"
            :key="index"
          >
            <svg-icon
              :class="['svg', { active: active === index + 1 }]"
              :icon-class="item"
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- 样本细分 -->
    <div class="sample-egmentation-fa">
      <div class="sample-egmentation">
        <div class="title">{{ $t("consumer.page5.title1") }}</div>
        <div class="tips">{{ $t("consumer.page5.tips1") }}</div>
        <div class="tips">{{ $t("consumer.page5.tips2") }}</div>
        <div class="acrd-boxs">
          <div
            class="card-item"
            v-for="(item, index) in sampleList"
            :key="item.name"
          >
            <img :src="item.url" />
            <div :class="['name', { namee: $i18n.locale === 'en' }]">
              {{ $t(`consumer.page5.${item.name}`) }}
            </div>
            <div
              :class="['tip', { tipe: $i18n.locale === 'en' }]"
              v-for="cItem in 5"
              :key="cItem"
            >
              {{ $t(`consumer.page5.tipBox${index + 1}.tips${cItem}`) }}
            </div>
            <div :class="['tip', { tipe: $i18n.locale === 'en' }]">. . .</div>
          </div>
        </div>
        <div class="acrd-boxs2">
          <div class="card-fa">
            <div
              class="card-item"
              v-for="(item, index) in sampleList1"
              :key="item.name"
            >
              <img :src="item.url" />
              <div :class="['name', { namee: $i18n.locale === 'en' }]">
                {{ $t(`consumer.page5.${item.name}`) }}
              </div>
              <div
                :class="['tip', { tipe: $i18n.locale === 'en' }]"
                v-for="cItem in 5"
                :key="cItem"
              >
                {{ $t(`consumer.page5.tipBox${index + 1}.tips${cItem}`) }}
              </div>
              <div :class="['tip', { tipe: $i18n.locale === 'en' }]">. . .</div>
            </div>
          </div>
          <div class="card-fa">
            <div
              class="card-item"
              v-for="(item, index) in sampleList2"
              :key="item.name"
            >
              <img :src="item.url" />
              <div :class="['name', { namee: $i18n.locale === 'en' }]">
                {{ $t(`consumer.page5.${item.name}`) }}
              </div>
              <div
                :class="['tip', { tipe: $i18n.locale === 'en' }]"
                v-for="cItem in 5"
                :key="cItem"
              >
                {{ $t(`consumer.page5.tipBox${index + 1}.tips${cItem}`) }}
              </div>
              <div :class="['tip', { tipe: $i18n.locale === 'en' }]">. . .</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sample-egmentation2">
        <div class="img-box">
          <img src="@/assets/consumer/map.png" />
          <div class="title">{{ $t("consumer.page5.title1") }}</div>
          <div class="tips">{{ $t("consumer.page5.tips1") }}</div>
          <div class="tips">{{ $t("consumer.page5.tips2") }}</div>
        </div>
        <div class="acrd-boxs">
          <div
            class="card-item"
            v-for="(item, index) in sampleList"
            :key="item.name"
          >
            <img :src="item.url" />
            <div :class="['name', { namee: $i18n.locale === 'en' }]">
              {{ $t(`consumer.page5.${item.name}`) }}
            </div>
            <div
              :class="['tip', { tipe: $i18n.locale === 'en' }]"
              v-for="cItem in 5"
              :key="cItem"
            >
              {{ $t(`consumer.page5.tipBox${index + 1}.tips${cItem}`) }}
            </div>
            <div :class="['tip', { tipe: $i18n.locale === 'en' }]">. . .</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "consumer",
  data() {
    return {
      movingAmples: false,
      secondList: [
        {
          url: require("@/assets/consumer/second1.png"),
        },
        {
          url: require("@/assets/consumer/second2.png"),
        },
        {
          url: require("@/assets/consumer/second3.png"),
        },
        {
          url: require("@/assets/consumer/second4.png"),
        },
      ],
      lableList: [
        {
          number: 5,
        },
        {
          number: 4,
        },
        {
          number: 3,
        },
      ],
      active: 1,
      timer: null,
      svgList: ["top", "right", "bottom", "left"],
      sampleList: [
        {
          url: require("@/assets/consumer/vehicle.png"),
          name: "name1",
        },
        {
          url: require("@/assets/consumer/mother-baby.png"),
          name: "name2",
        },
        {
          url: require("@/assets/consumer/tourism.png"),
          name: "name3",
        },
        {
          url: require("@/assets/consumer/tv.png"),
          name: "name4",
        },
      ],
      sampleList1: [
        {
          url: require("@/assets/consumer/vehicle.png"),
          name: "name1",
        },
        {
          url: require("@/assets/consumer/mother-baby.png"),
          name: "name2",
        },
      ],
      sampleList2: [
        {
          url: require("@/assets/consumer/tourism.png"),
          name: "name3",
        },
        {
          url: require("@/assets/consumer/tv.png"),
          name: "name4",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      if (this.movingAmples) {
        return;
      }
      const el = document.getElementById("people");
      const rect = el.getBoundingClientRect();
      const vWidth = window.innerWidth || document.documentElement.clientWidth;
      const vHeight =
        window.innerHeight || document.documentElement.clientHeight;

      if (
        rect.right < 0 ||
        rect.bottom < 0 ||
        rect.left > vWidth ||
        rect.top > vHeight
      ) {
        // console.log("不在可视区域");
      } else {
        // console.log("在可视区域");
        this.movingAmples = true;
        document.getElementById("people").className = "people active";
      }
    },
    changeActive() {
      if (this.active < 4) {
        this.active++;
      } else {
        this.active = 1;
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    if (!this.movingAmples) {
      this.scrollToTop();
    }
    this.timer = setInterval(() => {
      this.changeActive();
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.consumer-main {
  // 第一屏
  .consumer-top {
    width: 100%;
    height: 500px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/consumer/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img-box {
      margin-left: 120px;
      img {
        width: 300px;
        height: 300px;
      }
    }
    .title-box {
      width: 600px;
      margin-right: 80px;
      .title {
        font-size: 34px;
        font-weight: bold;
        color: #fff;
      }
      .tips {
        color: #fff;
        font-size: 18px;
        margin-top: 8px;
      }
    }
    .namelang {
      width: 950px;
      .title {
        font-size: 30px;
        font-weight: bold;
        color: #fff;
      }
      .tips {
        color: #fff;
        font-size: 16px;
        margin-top: 8px;
      }
    }
    .more {
      width: 100px;
      height: 32px;
      line-height: 32px;
      background: linear-gradient(90deg, #ffce85 0%, #ffbf78 100%);
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      text-align: center;
      margin-top: 49px;
    }
  }
  //第二屏
  .consumer-second {
    display: flex;
    justify-content: center;
    margin-top: 160px;
    margin-bottom: 100px;
    .card-item {
      margin-right: 16px;
      width: 287px;
      height: 287px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-top: 35px;
      }
      .title {
        color: #333333;
        font-size: 18px;
        font-weight: bolder;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      .tips {
        font-size: 14px;
        color: #666666;
        margin-bottom: 2px;
        text-align: center;
      }
    }
    .card-item:last-child {
      margin-right: 0;
    }
  }
  // 第三屏
  .massive {
    align-items: center;
    background: #f7fbff;
    .massive-box {
      height: 640px;
      display: flex;
      width: calc(100% - 240px);
      margin-left: 120px;
      justify-content: center;
      align-items: center;
    }
    .left {
      flex-shrink: 0;
      position: relative;
      .bg2 {
        width: 532px;
        height: 232px;
      }
      .circle {
        width: 235px;
        height: 235px;
        position: absolute;
        top: -42px;
        left: 145px;
        z-index: 99;
        animation: rotation 5s linear infinite;
      }
      .people {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 45px;
        left: 234px;
        z-index: 100;
        opacity: 0;
        transition: opacity 1.2s ease 1s;
      }
      .active {
        opacity: 1;
      }
      .title-boxs {
        display: flex;
        width: 532px;
        position: relative;
        margin-top: 25px;
        .left-boxs {
          width: 120px;
          position: absolute;
          left: -60px;
          text-align: center;
        }
        .middle-boxs {
          position: absolute;
          left: 159px;
          width: 200px;
          text-align: center;
        }
        .right-boxs {
          position: absolute;
          left: 466px;
          width: 120px;
          text-align: center;
        }
        .title {
          font-size: #666666;
          font-size: 18px;
        }
        .tips {
          font-size: #232323;
          font-size: 20px;
          font-weight: bolder;
        }
      }
      .money-btn {
        padding: 0;
        margin: 0;
        border: none;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px;
        margin-top: 100px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        font-size: 16px;
        color: #fff;
        position: relative;
        left: 167px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }
    }
    .right {
      flex-shrink: 0;
      margin-left: 70px;
      .title {
        font-size: 28px;
        color: #232323;
        font-weight: bolder;
        margin-bottom: 30px;
      }
      .tips {
        font-size: 16px;
        color: #232323;
        margin-bottom: 6px;
      }
      .line-box {
        display: flex;
        flex-direction: column;
        margin-top: 57px;
        .lable-box {
          display: flex;
          align-items: center;
          .lable-name {
            font-size: 16px;
            color: #232323;
            margin-right: 16px;
            min-width: 100px;
          }
          .lable {
            height: 32px;
            line-height: 32px;
            padding: 0 16px;
            border-radius: 16px;
            background-color: #fff;
            font-size: 14px;
            color: #232323;
            box-shadow: 0px 3px 10px 1px #dee9f5;
            margin-right: 20px;
          }
        }
      }
      .line-box:first-child {
        margin-top: 73px;
      }
    }
    .title-box {
      display: none;
    }
    .rightz {
      margin-left: 100px;
    }
  }
  //质量保证
  .quality-assurance {
    width: calc(100% - 240px);
    margin-left: 120px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 600px;
    .left {
      margin-right: 80px;
      .title {
        font-size: 28px;
        color: #232323;
        font-weight: bolder;
      }
      .tips-box {
        font-size: 16px;
        color: #232323;
        margin-top: 53px;
        .tips {
          margin-bottom: 14px;
        }
      }
    }
    .right {
      .img-box {
        width: 408px;
        height: 408px;
        position: relative;
        .elliptic {
          width: 100%;
          height: 100%;
        }
        .center-circle {
          position: absolute;
          width: 146px;
          height: 146px;
          left: 131px;
          top: 131px;
        }
        .card-box {
          position: absolute;
          width: 104px;
          height: 104px;
          z-index: 999;
          background-color: #f5f6fa;
          border-radius: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          .svg {
            width: 62px;
            height: 62px;
            color: #bbc4d0;
            transition: color 0.75s;
          }
          .active {
            color: #0b7cff;
          }
        }
        .top {
          top: -52px;
          left: 50%;
          transform: translateX(-50%);
        }
        .left {
          top: 50%;
          left: -52px;
          transform: translateY(-50%);
        }
        .right {
          top: 50%;
          right: -52px;
          transform: translateY(-50%);
        }
        .bottom {
          bottom: -52px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  //样本细分
  .sample-egmentation-fa {
    background-color: #f7fbff;
    width: 100%;
    margin-bottom: 100px;
    .sample-egmentation {
      width: calc(100% - 240px);
      margin-left: 120px;
      height: 623px;
      background-image: url("../assets/consumer/map.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 100px;
      margin-bottom: 150px;
      position: relative;
      .title {
        text-align: center;
        font-size: 26px;
        font-weight: bolder;
        color: #232323;
        padding-top: 66px;
        margin-bottom: 12px;
      }
      .tips {
        text-align: center;
        font-size: 16px;
        color: #232323;
        margin-bottom: 19px;
      }
      .acrd-boxs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: -98px;
        .card-item {
          width: 287px;
          height: 348px;
          background: #0b7cff;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          position: relative;
          color: #fff;
          font-size: 14px;
          img {
            position: absolute;
            width: 108px;
            height: 108px;
            top: -54px;
            right: 11px;
          }
          .name {
            margin-top: 65px;
            margin-left: 49px;
            font-size: 24px;
            margin-bottom: 20px;
          }
          .namee {
            margin-left: 20px;
          }
          .tip {
            margin-left: 49px;
            margin-bottom: 15px;
          }
          .tipe {
            margin-left: 20px;
          }
        }
      }
      .acrd-boxs2 {
        display: none;
      }
    }
    .sample-egmentation2 {
      display: none;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss" scoped>
/* 媒体查询 */
@media only screen and (max-width: 1400px) {
  .consumer-main {
    .sample-egmentation {
      width: calc(100% - 80px);
      margin-left: 40px;
    }
  }
}

@media only screen and (max-width: 1350px) {
  // 第三屏
  .consumer-main {
    .massive {
      align-items: center;
      background: #f7fbff;
      .massive-box {
        padding: 100px 0;
        height: auto;
        display: flex;
        width: calc(100% - 40px);
        margin-left: 20px;
        justify-content: center;
        align-items: center;
      }
      .left {
        flex-shrink: 0;
        position: relative;
        .bg2 {
          width: 360px;
          height: 156px;
        }
        .circle {
          width: 160px;
          height: 160px;
          position: absolute;
          top: -28px;
          left: 98px;
          z-index: 99;
          animation: rotation 5s linear infinite;
        }
        .people {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 30px;
          left: 158px;
          z-index: 100;
          opacity: 0;
          transition: opacity 1.2s ease 1s;
        }
        .active {
          opacity: 1;
        }
        .title-boxs {
          display: flex;
          width: 360px;
          position: relative;
          margin-top: 14px;
          .left-boxs {
            width: 82px;
            position: absolute;
            left: -33px;
            text-align: center;
          }
          .middle-boxs {
            position: absolute;
            left: 107px;
            width: 135px;
            text-align: center;
          }
          .right-boxs {
            position: absolute;
            left: 315px;
            width: 80px;
            text-align: center;
          }
          .title {
            font-size: #666666;
            font-size: 12px;
          }
          .tips {
            font-size: #232323;
            font-size: 13px;
            font-weight: bolder;
          }
        }
        .money-btn {
          padding: 0;
          margin: 0;
          border: none;
          height: 34px;
          background: #0b7cff;
          border-radius: 17px;
          margin-top: 68px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          font-size: 12px;
          color: #fff;
          position: relative;
          left: 113px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 12px;
          }
        }
      }
      .right {
        flex-shrink: 0;
        margin-left: 48px;
        .title {
          font-size: 21px;
          color: #232323;
          font-weight: bolder;
          margin-bottom: 19px;
        }
        .tips {
          font-size: 12px;
          color: #232323;
          margin-bottom: 8px;
        }
        .line-box {
          display: flex;
          flex-direction: column;
          margin-top: 38px;
          .lable-box {
            display: flex;
            align-items: center;
            .lable-name {
              font-size: 12px;
              color: #232323;
              margin-right: 12px;
              min-width: 68px;
            }
            .lable {
              height: 22px;
              line-height: 22px;
              padding: 0 12px;
              border-radius: 12px;
              background-color: #fff;
              font-size: 12px;
              color: #232323;
              box-shadow: 0px 3.6px 6px 1px #dee9f5;
              margin-right: 17px;
            }
          }
        }
        .line-box:first-child {
          margin-top: 73px;
        }
      }
      .rightz {
        margin-left: 68px;
      }
    }
    .quality-assurance {
      width: calc(100% - 80px);
      margin-left: 40px;
      .left {
        margin-right: 0;
      }
      .right {
        width: 500px;
        flex-shrink: 0;
        .img-box {
          width: 326px;
          height: 326px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          .elliptic {
            width: 100%;
            height: 100%;
          }
          .center-circle {
            position: absolute;
            width: 116px;
            height: 116px;
            left: 104px;
            top: 104px;
          }
          .card-box {
            position: absolute;
            width: 84px;
            height: 84px;
            z-index: 999;
            background-color: #f5f6fa;
            border-radius: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg {
              width: 50px;
              height: 50px;
              color: #bbc4d0;
              transition: color 0.75s;
            }
            .active {
              color: #0b7cff;
            }
          }
          .top {
            top: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
          .left {
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
          }
          .right {
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
          }
          .bottom {
            bottom: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .consumer-main {
    .consumer-second {
      flex-wrap: wrap;
      .card-item {
        margin-right: 0;
        width: 50%;
      }
      .card-item:last-child {
        margin-right: 0;
      }
    }
    .sample-egmentation-fa {
      .sample-egmentation {
        width: calc(100% - 80px);
        margin-left: 40px;
        margin-top: 30px;
        margin-bottom: 600px;
        .acrd-boxs {
          display: none;
        }
        .acrd-boxs2 {
          width: 100%;
          position: absolute;
          bottom: -600px;
          display: block;
          .card-fa {
            display: flex;
            justify-content: space-around;
            .card-item {
              width: 287px;
              height: 348px;
              background: #0b7cff;
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              position: relative;
              color: #fff;
              font-size: 14px;
              margin-bottom: 100px;
              img {
                position: absolute;
                width: 108px;
                height: 108px;
                top: -54px;
                right: 11px;
              }
              .name {
                margin-top: 65px;
                margin-left: 49px;
                font-size: 24px;
                margin-bottom: 20px;
              }
              .namee {
                margin-left: 20px;
              }
              .tip {
                margin-left: 49px;
                margin-bottom: 15px;
              }
              .tipe {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .consumer-main {
    .consumer-top {
      width: 100%;
      height: 500px;
      background-color: #000;
      position: relative;
      background-image: url("../assets/consumer/bg1.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img-box {
        display: none;
        margin-left: 120px;
        img {
          width: 300px;
          height: 300px;
        }
      }
      .title-box {
        width: 100%;
        padding: 0 40px;
        .title {
          font-size: 34px;
          font-weight: bold;
          color: #fff;
        }
        .tips {
          color: #fff;
          font-size: 18px;
          margin-top: 8px;
        }
      }
      .namelang {
        width: 950px;
        .title {
          font-size: 30px;
          font-weight: bold;
          color: #fff;
        }
        .tips {
          color: #fff;
          font-size: 16px;
          margin-top: 8px;
        }
      }
      .more {
        width: 100px;
        height: 32px;
        line-height: 32px;
        background: linear-gradient(90deg, #ffce85 0%, #ffbf78 100%);
        border-radius: 16px 16px 16px 16px;
        opacity: 1;
        text-align: center;
        margin-top: 49px;
      }
    }
    // 第三屏
    .massive {
      background: #f7fbff;
      .massive-box {
        height: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        justify-content: center;
        align-items: center;
        padding: 70px 0 100px 0;
      }
      .title-box {
        display: block;
        .title {
          font-size: 20px;
          text-align: center;
          color: #232323;
          font-weight: bolder;
          margin-bottom: 35px;
        }
        .tips {
          font-size: 14px;
          text-align: center;
          color: #232323;
        }
      }
      .left {
        flex-shrink: 0;
        position: relative;
        margin-top: 100px;
        transform: scale(0.8);
        .bg2 {
          width: 532px;
          height: 232px;
        }
        .circle {
          width: 235px;
          height: 235px;
          position: absolute;
          top: -42px;
          left: 145px;
          z-index: 99;
          animation: rotation 5s linear infinite;
        }
        .people {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 45px;
          left: 234px;
          z-index: 100;
          opacity: 0;
          transition: opacity 1.2s ease 1s;
        }
        .active {
          opacity: 1;
        }
        .title-boxs {
          display: flex;
          width: 532px;
          position: relative;
          margin-top: 25px;
          .left-boxs {
            width: 120px;
            position: absolute;
            left: -60px;
            text-align: center;
          }
          .middle-boxs {
            position: absolute;
            left: 159px;
            width: 200px;
            text-align: center;
          }
          .right-boxs {
            position: absolute;
            left: 466px;
            width: 120px;
            text-align: center;
          }
          .title {
            font-size: #666666;
            font-size: 18px;
          }
          .tips {
            font-size: #232323;
            font-size: 20px;
            font-weight: bolder;
          }
        }
        .money-btn {
          padding: 0;
          margin: 0;
          border: none;
          height: 50px;
          background: #0b7cff;
          border-radius: 25px;
          margin-top: 100px;
          display: flex;
          align-items: center;
          padding: 0 24px;
          font-size: 16px;
          color: #fff;
          position: relative;
          left: 167px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 16px;
          }
        }
      }
      .right {
        flex-shrink: 0;
        margin-left: 70px;
        .title {
          display: none;
        }
        .tips {
          display: none;
        }
        .line-box {
          display: flex;
          flex-direction: column;
          margin-top: 57px;
          .lable-box {
            display: flex;
            align-items: center;
            .lable-name {
              font-size: 16px;
              color: #232323;
              margin-right: 16px;
              min-width: 100px;
            }
            .lable {
              height: 32px;
              line-height: 32px;
              padding: 0 16px;
              border-radius: 16px;
              background-color: #fff;
              font-size: 14px;
              color: #232323;
              box-shadow: 0px 3px 10px 1px #dee9f5;
              margin-right: 20px;
            }
          }
        }
        .line-box:first-child {
          margin-top: 73px;
        }
      }
      .rightz {
        margin-left: 0px;
      }
    }
    .quality-assurance {
      flex-direction: column;
      width: calc(100% - 80px);
      margin-left: 40px;
      padding-bottom: 300px;
      .left {
        width: 100%;
        margin-right: 0;
        text-align: center;
      }
      .right {
        width: 100%;
        flex-shrink: 0;
        margin-top: 100px;
        position: static;
        .img-box {
          width: 326px;
          height: 326px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          .elliptic {
            width: 100%;
            height: 100%;
          }
          .center-circle {
            position: absolute;
            width: 116px;
            height: 116px;
            left: 104px;
            top: 104px;
          }
          .card-box {
            position: absolute;
            width: 84px;
            height: 84px;
            z-index: 999;
            background-color: #f5f6fa;
            border-radius: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg {
              width: 50px;
              height: 50px;
              color: #bbc4d0;
              transition: color 0.75s;
            }
            .active {
              color: #0b7cff;
            }
          }
          .top {
            top: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
          .left {
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
          }
          .right {
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            margin-top: 0;
          }
          .bottom {
            bottom: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .consumer-main {
    // 第一屏
    .consumer-top {
      height: 360px;
      .title-box {
        width: calc(100% - 40px);
        padding: 0 20px;
        margin-top: 10px;
        padding: 0 20px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #fff;
        }
        .tips {
          color: #fff;
          font-size: 14px;
          margin-top: 8px;
        }
      }
      .namelang {
        width: calc(100% - 40px);
        padding: 0 20px;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #fff;
        }
        .tips {
          color: #fff;
          font-size: 14px;
          margin-top: 8px;
        }
      }
      .more {
        font-size: 14px;
      }
    }
    .consumer-second {
      margin-top: 0;
      .card-item {
        margin-right: 0;
        width: 100%;
        margin-top: 50px;
      }
    }
    // 第三屏
    .massive {
      background: #f7fbff;
      .massive-box {
        height: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        justify-content: center;
        align-items: center;
        padding: 70px 0 100px 0;
      }
      .title-box {
        display: block;
        .title {
          font-size: 20px;
          text-align: center;
          color: #232323;
          font-weight: bolder;
          margin-bottom: 35px;
        }
        .tips {
          font-size: 14px;
          text-align: center;
          color: #232323;
        }
      }
      .left {
        flex-shrink: 0;
        position: relative;
        margin-top: 50px;
        transform: scale(0.7);
        .bg2 {
          width: 532px;
          height: 232px;
        }
        .circle {
          width: 235px;
          height: 235px;
          position: absolute;
          top: -42px;
          left: 145px;
          z-index: 99;
          animation: rotation 5s linear infinite;
        }
        .people {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 45px;
          left: 234px;
          z-index: 100;
          opacity: 0;
          transition: opacity 1.2s ease 1s;
        }
        .active {
          opacity: 1;
        }
        .title-boxs {
          display: flex;
          width: 532px;
          position: relative;
          margin-top: 25px;
          .left-boxs {
            width: 120px;
            position: absolute;
            left: -60px;
            text-align: center;
          }
          .middle-boxs {
            position: absolute;
            left: 159px;
            width: 200px;
            text-align: center;
          }
          .right-boxs {
            position: absolute;
            left: 466px;
            width: 120px;
            text-align: center;
          }
          .title {
            font-size: #666666;
            font-size: 18px;
          }
          .tips {
            font-size: #232323;
            font-size: 20px;
            font-weight: bolder;
          }
        }
        .money-btn {
          padding: 0;
          margin: 0;
          border: none;
          height: 50px;
          background: #0b7cff;
          border-radius: 25px;
          margin-top: 100px;
          display: flex;
          align-items: center;
          padding: 0 24px;
          font-size: 16px;
          color: #fff;
          position: relative;
          left: 167px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 16px;
          }
        }
      }
      .right {
        flex-shrink: 0;
        margin-left: 0px;
        margin-top: 0;
        padding: 0 10px;
        .title {
          display: none;
        }
        .tips {
          display: none;
        }
        .line-box {
          display: flex;
          flex-direction: column;
          margin-top: 57px;
          .lable-box {
            display: flex;
            align-items: center;
            .lable-name {
              font-size: 14px;
              color: #232323;
              margin-right: 10px;
              min-width: 0;
              width: 80px;
              flex-shrink: 0;
            }
            .lable {
              height: 32px;
              line-height: 32px;
              padding: 0 16px;
              border-radius: 16px;
              background-color: #fff;
              font-size: 12px;
              color: #232323;
              box-shadow: 0px 3px 10px 1px #dee9f5;
              margin-right: 10px;
            }
          }
        }
        .line-box:first-child {
          margin-top: 73px;
        }
      }
      .rightz {
        margin-left: 0px;
      }
    }
    .quality-assurance {
      flex-direction: column;
      width: calc(100% - 40px);
      margin-left: 20px;
      padding-bottom: 100px;
      margin-top: 10px;
      .left {
        width: 100%;
        margin-right: 0;
        text-align: center;
        .title {
          font-size: 20px;
        }
        .tips-box {
          font-size: 14px;
          margin-top: 30px;
          .tips {
            margin-bottom: 10px;
          }
        }
      }
      .right {
        width: 100%;
        flex-shrink: 0;
        margin-top: 100px;
        position: static;
        .img-box {
          width: 326px;
          height: 326px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          .elliptic {
            width: 100%;
            height: 100%;
          }
          .center-circle {
            position: absolute;
            width: 116px;
            height: 116px;
            left: 104px;
            top: 104px;
          }
          .card-box {
            position: absolute;
            width: 84px;
            height: 84px;
            z-index: 999;
            background-color: #f5f6fa;
            border-radius: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg {
              width: 50px;
              height: 50px;
              color: #bbc4d0;
              transition: color 0.75s;
            }
            .active {
              color: #0b7cff;
            }
          }
          .top {
            top: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
          .left {
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
          }
          .right {
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            margin-top: 0;
          }
          .bottom {
            bottom: -52px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .sample-egmentation-fa {
      .sample-egmentation {
        display: none;
      }
      .sample-egmentation2 {
        display: block;
        margin-top: 90px;
        .img-box {
          img {
            width: 100%;
            height: auto;
            position: absolute;
          }
          .title {
            text-align: center;
            font-size: 20px;
            font-weight: bolder;
            color: #232323;
            margin-bottom: 12px;
            text-align: center;
            padding: 0 20px;
          }
          .tips {
            text-align: center;
            font-size: 14px;
            color: #232323;
            margin-bottom: 19px;
            padding: 0 20px;
          }
        }
        .acrd-boxs {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .card-item {
            width: 287px;
            height: 348px;
            background: #0b7cff;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            position: relative;
            color: #fff;
            font-size: 14px;
            margin-bottom: 100px;
            img {
              position: absolute;
              width: 108px;
              height: 108px;
              top: -54px;
              right: 11px;
            }
            .name {
              margin-top: 65px;
              margin-left: 49px;
              font-size: 24px;
              margin-bottom: 20px;
            }
            .namee {
              margin-left: 20px;
            }
            .tip {
              margin-left: 49px;
              margin-bottom: 15px;
            }
            .tipe {
              margin-left: 20px;
            }
          }
          .card-item:first-child {
            margin-top: 40px;
          }
        }
      }
    }
  }
}
</style>